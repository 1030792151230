import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { IInduction } from '../core/interfaces/induction.interface';

@Injectable({ providedIn: 'root' })
export class FormService {

  constructor(private http: HttpClient) {
  }

  submitInduction(client:string, induction: IInduction) {
    return this.http.post<any>(`${environment.apiUrl}/submitInduction-v2/${client}`, induction);
  }

  sendQrCode(id: string) {
    return this.http.get<IInduction>(`${environment.apiUrl}/sendqrcode/${id}`);
  }
}

