<section class="main-content profile-content">
  <div class="container">
    <div class="caption">
      <h1 class="mb-3">{{config?.heading}} Safety Induction Pass</h1>
      <h2 *ngIf="inductionProfile" class="mb-2" [ngClass]="{'validpass': isValidPass, 'invalidpass' : !isValidPass }">
        {{isValidPass ? 'VALID': 'EXPIRED'}}
      </h2>
    </div>
    <form *ngIf="inductionProfile">

      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-5 col-md-5">
              <div class="row">
                <div class="col-md-12 col-sm-6">
                  <div class="row align-items-center form-group">
                    <div class="col-md-4">
                      <label>Site:</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" class="form-control" [value]="inductionProfile.site" readonly>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-6">
                  <div class="row align-items-center form-group">
                    <div class="col-md-4">
                      <label>Name:</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" class="form-control" [value]="inductionProfile.name" readonly>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-6">
                  <div class="row align-items-center form-group">
                    <div class="col-md-4">
                      <label>Mobile No:</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" class="form-control" [value]="inductionProfile.mobileNo" readonly>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-6">
                  <div class="row align-items-center form-group">
                    <div class="col-md-4">
                      <label>Email Address:</label>
                    </div>
                    <div class="col-md-8">
                      <input type="email" class="form-control" [value]="inductionProfile.email" readonly>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-7 col-md-7">
              <div class="row">
                <div class="col-md-12 col-sm-6">
                  <div class="row align-items-center form-group">
                    <div class="col-md-4">
                      <label>Company Name:</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" class="form-control" [value]="inductionProfile.company" readonly>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-6">
                  <div class="row align-items-center form-group">
                    <div class="col-md-4">
                      <label>Visit Date:</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" class="form-control"
                        value="{{inductionProfile.visitDateTime | date:'medium' }}" readonly>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-6">
                  <div class="row align-items-center form-group">
                    <div class="col-md-4">
                      <label>Expiry Date:</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" class="form-control" value="{{inductionProfile.expiryDate | date:'medium' }}"
                        readonly>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="caption">
        <h3 class="text-center my-3">Site Contact</h3>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-5 col-md-5">
              <div class="row align-items-center form-group">
                <div class="col-md-4">
                  <label>Contact on site:</label>
                </div>
                <div class="col-md-8">
                  <input type="text" class="form-control" [value]="inductionProfile.alecContactName" readonly>
                </div>
              </div>
            </div>
            <div class="col-lg-7 col-md-7">
              <div class="row align-items-center form-group">
                <div class="col-md-4">
                  <label>Contact Person's Number:</label>
                </div>
                <div class="col-md-8">
                  <input type="text" class="form-control" [value]="inductionProfile.alecContactPhone" readonly>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-md-5">
              <div class="row align-items-center form-group">
                <div class="col-md-4">
                  <label>Contact email site:</label>
                </div>
                <div class="col-md-8">
                  <input type="email" class="form-control" [value]="inductionProfile.alecContactEmail" readonly>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

  </div>
</section>