import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { map } from 'rxjs';
import { IConfig } from 'src/app/core/interfaces/config.interface';
import { ILookup } from 'src/app/core/interfaces/lookup.interface';
import { ConfigService } from 'src/app/services/config.service';
import { DataService } from 'src/app/services/data.service';
import { FormService } from 'src/app/services/form.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-visitor',
  templateUrl: './visitor.component.html',
  styleUrls: ['./visitor.component.scss'],
})
export class VisitorComponent implements OnInit {
  inductionForm: FormGroup;
  public sites: ILookup[] = [];
  public objOther: ILookup = {
    id: 'other',
    name: 'Other',
  };
  config: IConfig;

  siteKey: string;
  isSubmitted: boolean;
  minDate: any;
  isOtherSite: Boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private dataSvc: DataService,
    private formSvc: FormService,
    private router: Router,
    private recaptchaSvc: ReCaptchaV3Service,
    private storageSvc: StorageService,
    private configSvc: ConfigService
  ) {
    this.configSvc.setConfiguration(location.hostname, location.pathname);
    this.storageSvc.configValue.subscribe((x) => (this.config = x));

    this.siteKey = environment.recaptchaSiteKey;
    const datePipe = new DatePipe('en-Us');
    this.minDate = datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.inductionForm = this.formBuilder.group({
      site: [null, Validators.required],
      otherSite: [null],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],

      mobileNo: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(8),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      company: ['', Validators.required],
      position: ['', Validators.required],

      visitTime: ['', Validators.required],
      visitDateTime: ['', Validators.required],

      emergencyContactName: ['', Validators.required],
      emergencyContactPhone: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(8),
        ],
      ],

      alecContactName: ['', Validators.required],
      alecContactPhone: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(8),
        ],
      ],
      alecContactEmail: [
        '',
        [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      ],

      isShareAgree: [false, Validators.requiredTrue],
      isSafetyAgree: [false, Validators.requiredTrue],

      recaptcha: ['', Validators.required], //,
    });

    this.dataSvc
      .getSites(this.config?.id)
      .pipe(
        map((x: ILookup[]) => x.filter((x) => x.name != null && x.name != ''))
      )
      .subscribe((x) => {
        this.sites = x;

        // if (this.sites.length > 0) {
        //   this.sites.push(this.objOther);
        // }

      });
  }

  ngOnInit(): void {
    this.setSiteValidator();
  }

  public setSiteValidator() {
    this.inductionForm.get('site').valueChanges.subscribe((x) => {
      if (x == 'Other') {
        this.inductionForm
          .get('otherSite')
          .setValidators([Validators.required]);
      } else {
        this.inductionForm.get('otherSite').setValidators(null);
      }

      this.inductionForm.get('otherSite').updateValueAndValidity();
    });
  }

  public onSiteChange(e) {
    let site = e.name;

    if (site == 'Other') this.isOtherSite = true;
    else {
      this.isOtherSite = false;
      this.inductionForm.patchValue({ otherSite: null }, { onlySelf: true });
    }
  }

  public submitInduction(): void {
    var induction = this.inductionForm.value;

    if (this.inductionForm.valid) {
      console.log(induction);
      this.isSubmitted = true;

      induction.visitDateTime = this.setDateTime(
        induction.visitDateTime,
        induction.visitTime
      );

      this.formSvc
        .submitInduction(this.config?.id, induction)
        .subscribe(async (response) => {
          console.log(response);
          if (response && response.success) {
            this.router.navigate([this.config.url + '/thank-you'], {
              queryParams: { response: JSON.stringify(response) },
            });
          } else if (!response.success && response.message) {
            alert(response.message);
          }

          this.isSubmitted = false;
        });
    }
  }

  private setDateTime(date: string, time: string): Date {
    var d = new Date(date);

    if (time) {
      d.setHours(parseInt(time.split(':')[0]));
      d.setMinutes(parseInt(time.split(':')[1]));
    }

    return d;
  }
}
