import { Component, Inject, Renderer2 } from '@angular/core';
import disableDevtool from 'disable-devtool';

import { DisableRightClickService } from './services/rightClick.service';
import { ThemeService } from './services/theme.service';
import { DOCUMENT, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from './services/data.service';
import { IConfig } from './core/interfaces/config.interface';
import { StorageService } from './services/storage.service';
import { Title } from '@angular/platform-browser';
import { ConfigService } from './services/config.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Alec Safety';
  config: IConfig;
  configs: IConfig;

  constructor(
    private dataSvc: DataService,
    private storageSvc: StorageService,
    private rightClickDisable: DisableRightClickService,
    private configSvc: ConfigService
  ) {
    this.configSvc.setConfiguration(location.hostname, location.pathname);
    this.storageSvc.configValue.subscribe((x) => (this.config = x));
  }

  ngOnInit() {
    this.rightClickDisable.disableRightClick();
    disableDevtool();
  }

  // @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
  //   let result = confirm("Changes you made may not be saved.");
  //   if (result) {
  //     // Do more processing...

  //   }
  //   return false; // stay on same page
  // }

  // @HostListener('document:keyup', ['$event'])
  // onKeyUp (event: KeyboardEvent) {
  //   if (event.code == 'PrintScreen' || event.key == 'F12') {
  //     event.preventDefault();
  //     return;
  //   }
  // }
}
