import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';


@Injectable({
  providedIn: 'root'
})
export class VideoGuard implements CanActivate {


  constructor(private router: Router, private storage: StorageService) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isVideoWatched = this.storage.getItem("videoWatched");
      //return true;
    if (isVideoWatched === "true") {
        return true;
      }

    this.router.navigateByUrl('/')
    return false;
  }
}
