import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IConfig } from '../core/interfaces/config.interface';

@Injectable({
  providedIn: 'root'
})

export class StorageService {

  private $isVideoWatched = new BehaviorSubject<boolean>(false);
  public isVideoWatched = this.$isVideoWatched.asObservable();

  private $config = new BehaviorSubject<IConfig>(null);


  public get isVideoWatchedValue(): boolean {
    return this.$isVideoWatched.value;
  }

  public get configValue(): Observable<IConfig> {
    return this.$config.asObservable();
}

  constructor() { }

  public setItem(key: string, value: any) {
    sessionStorage.setItem(key, value);
  }

  public getItem(key: string) {
    return sessionStorage.getItem(key)
  }
  public removeItem(key: string) {
    sessionStorage.removeItem(key);
  }
  public clear() {
    sessionStorage.clear();
  }

  public setVideoWatched() {
    this.setItem('videoWatched', true);
    this.$isVideoWatched.next(true);
  }

  public setConfig(value: any) {
    this.setItem('config', JSON.stringify(value));
    this.$config.next(value);
  }

  clearVideoWatched() {
    this.removeItem('videoWatched');
    this.$isVideoWatched.next(false);
  }
}