<section class="main-content thanksMain">
  <div class="container"*ngIf="config">
    <div class="caption">
      <h1 class="mb-2">Thank you for watching the video</h1>
      <h2 class="mb-2">Please select a category</h2>
      <p>If you are an external visitor please click on the visitor button. If you are an existing {{config.heading}} employee please click on the {{config.heading}} employee button.</p>
    </div>
    <ul class="visitorLinks">
      <li><a routerLink="{{config.url + '/visitor'}}" class="btn btn-primary">Visitor</a></li>
      <li><a routerLink="{{config.url + '/employee'}}" class="btn btn-primary">{{config?.thankyouLabel}} Employee</a></li>
    </ul>
 </div>
</section>
