<div class="content-main" *ngIf="config">
  <section class="completing" [style.background-image]="'url(' + config.completeImageUrl + ')'">
    <div class="container">
      <h3 *ngIf="!isOld">Thank You for Completing the {{config?.heading}} online Safety Induction</h3>
      <h4 *ngIf="!isOld">Your QR Code will be sent to your email</h4>

      <h3 *ngIf="isOld">You already have a VALID {{config?.heading}} Safety Induction Pass</h3>
      <h4 *ngIf="isOld">We have resent the QR Code to your email</h4>
      <p>Please must present this QR code on site</p>

      <div *ngIf="response" style="text-align: center;padding: 20px;">
        <img [src]="response.qrCode" width="350" />
        <p>Issue Date: {{response.issueDate}}</p>
        <p>This QR Code is valid for {{response.validity}}</p>

        <p><br><br>Please note that this QR code may only be used by the person to whom it has been issued.<br> You
          should not share it with others.</p>

      </div>

    </div>

  </section>
</div>