import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IConfig } from 'src/app/core/interfaces/config.interface';
import { IInduction } from 'src/app/core/interfaces/induction.interface';
import { Theme } from 'src/app/core/interfaces/theme.interface';
import { DataService } from 'src/app/services/data.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-induction-profile',
  templateUrl: './induction-profile.component.html',
  styleUrls: ['./induction-profile.component.scss'],
})
export class InductionProfileComponent implements OnInit {
  inductionProfile: IInduction | undefined;
  id: string = '';
  client: string = '';
  isValidPass: boolean = false;
  config: IConfig;

  constructor(
    private dataSvc: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private themeSvc: ThemeService,
    private renderer2: Renderer2
  ) {
    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
      this.client = params['client'];

      if (this.id) {
        this.dataSvc.getConfig(this.client).subscribe((y) => {
          //this.themeSvc.setTheme(y?.heading, this.renderer2);
          this.dataSvc.getInduction(this.id, this.client).subscribe((x) => {
            if (x) {
              this.inductionProfile = x;

              var expiryDate = new Date(this.inductionProfile.expiryDate);

              if (new Date() <= expiryDate) {
                this.isValidPass = true;
              }
            }
          });
        });
      }
    });
  }

  ngOnInit(): void {}
}
