<section class="main-content">
  <div class="container" *ngIf="config">
    <div class="caption">
      <h1>Welcome to the {{config?.heading}} Safety Induction</h1>
      <p>Follow the steps below to complete your safety induction.</p>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="card-main">
          <div class="img-title"><img src="../../assets/images/poster.webp" alt=""> </div>
          <div class="card-body">
            <p>We would like you to watch a short video before you arrive on site. </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card-main">
          <div class="img-title"><img src="../../assets/images/poster2.webp" alt=""> </div>
          <div class="card-body">
            <p>Please complete the safety induction form with your information afterwards. </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card-main">
          <div class="img-title"><img src="../../assets/images/poster3.webp" alt=""> </div>
          <div class="card-body">
            <p>You will receive a QR code after completing these two steps, which you must
                present upon your arrival on site. </p>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <a routerLink="{{config?.url + '/video'}}" class="btn btn-primary btnSafety">Click Here to Start <br> Your Safety <br> Induction </a>
    </div>
  </div>
</section>
