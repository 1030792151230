<section class="main-content home-content">
  <div class="container">
    <div class="caption">
      <h1>Welcome to the ALEC Safety Induction Portal</h1>
      <p>Please select the venture you would like to visit.</p>
    </div>
    <div class="row justify-content-center">

      <div class="col-lg-4" *ngFor="let config of configs | filter : 'displayInHomePage' : true">
        <div class="card-main" (click)="changeTheme(config)">
          <div class="img-title"><img [src]="config.imageUrl" alt=""> </div>
          <div class="card-body">
            <p>{{config.homePageDesc}}</p>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-4">
          <div class="card-main">
            <div class="img-title"><img src="../../assets/images/esa.jpg" alt=""> </div>
            <div class="card-body">
              <p>For El-Sief & ALEC Operations/Construction Projects in KSA</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card-main">
            <div class="img-title"><img src="../../assets/images/linq.jpg" alt=""> </div>
            <div class="card-body">
              <p>For LINQ Modular Factory Site Visits in Dubai, U.A.E</p>
            </div>
          </div>
        </div> -->
    </div>
  </div>
</section>