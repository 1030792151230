import { HttpClient } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable } from 'rxjs';

import { StorageService } from './storage.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataService } from './data.service';
import { ThemeService } from './theme.service';
import { IConfig } from '../core/interfaces/config.interface';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private renderer: Renderer2;
  constructor(
    private dataSvc: DataService,
    private storageSvc: StorageService,
    private themeSvc: ThemeService,
    private router: Router,
    private titleSvc: Title,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  configs: IConfig[] = null;
  setConfiguration(hostName: string, pathName: string) {
    this.configs = JSON.parse(this.storageSvc.getItem('configs')) as IConfig[];
    var browserUrl = hostName + pathName;

    if (this.configs) {
      let config;
      if (browserUrl.includes('linq')) {
        config = this.configs.filter((y) => y.id == 'linq')[0];
      } else if (browserUrl.includes('esa')) {
        config = this.configs.filter((y) => y.id == 'esa')[0];
      } else {
        config = this.configs.filter((y) => y.id == 'alec')[0];
      }

      this.storageSvc.setConfig(config);
      this.themeSvc.setTheme(config.id, this.renderer);

      if (!config?.isHomePage && pathName === '/')
        this.router.navigate([`/${config?.url}/${config?.subdomain}`]);
    } else {
      this.dataSvc.getConfigs().subscribe((x) => {
        this.storageSvc.setItem('configs', JSON.stringify(x));

        let config;
        if (browserUrl.includes('linq')) {
          config = x.filter((y) => y.id == 'linq')[0];
        } else if (browserUrl.includes('esa')) {
          config = x.filter((y) => y.id == 'esa')[0];
        } else {
          config = x.filter((y) => y.id == 'alec')[0];
        }

        this.storageSvc.setConfig(config);
        this.themeSvc.setTheme(config.id, this.renderer);
        this.titleSvc.setTitle(`${config.heading} | Safety Induction Pass`);
        if (!config?.isHomePage && pathName === '/')
          this.router.navigate([`/${config?.url}/${config?.subdomain}`]);
      });
    }
  }
}
