import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CompleteInductionComponent } from './components/complete-induction/complete-induction.component';
import { AlecEmployeesComponent } from './components/employees/employees.component';
import { InductionProfileComponent } from './components/induction-profile/induction-profile.component';
import { SafetyVideoComponent } from './components/safety-video/safety-video.component';
import { ThanksWatchingComponent } from './components/thanks-watching/thanks-watching.component';
import { VisitorComponent } from './components/visitor/visitor.component';
import { WelcomeSafetyInductionComponent } from './components/welcome-safety-induction/welcome-safety-induction.component';
import { VideoGuard } from './core/guards/video.guard';
import { HomePageComponent } from './components/home-page/home-page.component';


const routes: Routes = [
    // ALEC & ESA
    {
        path: '',
        component: HomePageComponent
    },
    {
        path: 'intro',
        component: WelcomeSafetyInductionComponent
    },
    
    {
        path: 'video',
        component: SafetyVideoComponent
    },
    {
        path: 'survey',
        component: ThanksWatchingComponent,
        canActivate: [VideoGuard]
    },
    {
        path: 'visitor',
        component: VisitorComponent,
        canActivate: [VideoGuard]
    },
    {
        path: 'employee',
        component: AlecEmployeesComponent,
        canActivate: [VideoGuard]
    },
    {
        path: 'thank-you',
        component: CompleteInductionComponent,
        canActivate: [VideoGuard]
    },
    {
        path: 'induction-profile',
        component: InductionProfileComponent
    },
    // LINQ
    {
        path: 'linq/intro',
        component: WelcomeSafetyInductionComponent
    },
    
    {
        path: 'linq/video',
        component: SafetyVideoComponent
    },
    {
        path: 'linq/survey',
        component: ThanksWatchingComponent,
        canActivate: [VideoGuard]
    },
    {
        path: 'linq/visitor',
        component: VisitorComponent,
        canActivate: [VideoGuard]
    },
    {
        path: 'linq/employee',
        component: AlecEmployeesComponent,
        canActivate: [VideoGuard]
    },
    {
        path: 'linq/thank-you',
        component: CompleteInductionComponent,
        canActivate: [VideoGuard]
    },
    {
        path: 'linq/induction-profile',
        component: InductionProfileComponent
    }


    
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [
        RouterModule
    ],
})

export class AppRoutingModule { }
export const routingComponent = [WelcomeSafetyInductionComponent, SafetyVideoComponent, ThanksWatchingComponent, InductionProfileComponent]