import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class DisableRightClickService {
  constructor() {}
  disableRightClick() {
    document.addEventListener('contextmenu', (event) =>
      event.preventDefault()
    );
  }
}