import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { NgxCaptchaModule } from 'ngx-captcha';

import { AppRoutingModule, routingComponent } from './app-routing.module';
import { AppComponent } from './app.component';
import { CompleteInductionComponent } from './components/complete-induction/complete-induction.component';
import { AlecEmployeesComponent } from './components/employees/employees.component';
import { InductionProfileComponent } from './components/induction-profile/induction-profile.component';
import { VisitorComponent } from './components/visitor/visitor.component';
import { CommonInterceptor } from './core/interceptor/common.interceptor';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { FilterPipe } from './core/pipes/filter.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    routingComponent,
    FooterComponent,
    VisitorComponent,
    AlecEmployeesComponent,
    CompleteInductionComponent,
    InductionProfileComponent,
    HomePageComponent,
    FilterPipe,
  ],
  imports: [
    NgSelectModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxCaptchaModule,

    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CommonInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
