import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { IInduction } from '../core/interfaces/induction.interface';
import { ILookup } from '../core/interfaces/lookup.interface';
import { IConfig } from '../core/interfaces/config.interface';

@Injectable({ providedIn: 'root' })
export class DataService {
  constructor(private http: HttpClient) {}

  getConfigs(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/configs-v2`);
  }

  getConfig(id:string): Observable<IConfig> {
    return this.http.get<IConfig>(`${environment.apiUrl}/config-v2/${id}`);
  }

  getSites(client: string): Observable<ILookup[]> {
    return this.http.get<ILookup[]>(`${environment.apiUrl}/sites-v2/${client}`);
  }

  getInduction(id: string, client: string): Observable<IInduction> {
    return this.http.get<IInduction>(`${environment.apiUrl}/induction-v2/${client}/${id}`);
  }
}
