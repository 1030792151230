import { Component, OnInit, AfterViewInit } from '@angular/core';
import { IConfig } from 'src/app/core/interfaces/config.interface';
import { ConfigService } from 'src/app/services/config.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-safety-video',
  templateUrl: './safety-video.component.html',
  styleUrls: ['./safety-video.component.scss'],
})
export class SafetyVideoComponent implements OnInit, AfterViewInit {
  config: IConfig;
  isVideoWatched;
  isWatched: boolean = false;

  constructor(
    private storageSvc: StorageService,
    private configSvc: ConfigService
  ) {
    this.configSvc.setConfiguration(location.hostname, location.pathname);
    this.storageSvc.configValue.subscribe((x) => (this.config = x));
    this.isVideoWatched = this.storageSvc.getItem('videoWatched');

    if (this.isVideoWatched === 'true') {
      this.isWatched = true;
    }
  }
  ngAfterViewInit(): void {
    if (this.isWatched) {
      let demo = document.getElementById('demo');
      let lock = document.getElementById('lock');
      let play = document.getElementById('playBtn');

      if (play != null) {
        play.style.display = 'none';
      }

      if (demo != null) {
        demo.style.display = 'block';
      }

      if (lock != null) {
        lock.classList.remove('disabled');
      }
    }
  }

  ngOnInit(): void {}

  onVideoEnd() {
    let demo = document.getElementById('demo');
    let lock = document.getElementById('lock');

    if (demo != null) {
      demo.style.display = 'block';
    }

    if (lock != null) {
      lock.classList.remove('disabled');
    }

    this.storageSvc.setVideoWatched();
  }
}
