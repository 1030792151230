<header>
  <div class="container">
    <div class="topBar">
      <a href="#" class="brandName">
        <img src="../../../assets/images/{{config == null ? 'alec_logo.png': config.id + '_logo.png'}}" alt="">
      </a>
      <!-- <div class="right-nav">
        <ul>
          <li>
            <a href="#">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24"
                preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20">
                <path fill="white"
                  d="M12.2 13.6a7 7 0 1 1 1.4-1.4l5.4 5.4l-1.4 1.4l-5.4-5.4zM3 8a5 5 0 1 0 10 0A5 5 0 1 0 3 8z" />
              </svg>
            </a>
          </li>
          <li>
            <a href="#">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24"
                preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                <rect width="18" height="2" x="3" y="11" fill="white" rx=".95" ry=".95" />
                <rect width="18" height="2" x="3" y="16" fill="white" rx=".95" ry=".95" />
                <rect width="18" height="2" x="3" y="6" fill="white" rx=".95" ry=".95" />
              </svg>
            </a>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</header>