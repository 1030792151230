import { Component, OnInit } from '@angular/core';
import { IConfig } from 'src/app/core/interfaces/config.interface';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  config: IConfig;

  constructor(private storage: StorageService) {
    // this.config = JSON.parse(this.storage.getItem('config'));
    this.storage.configValue.subscribe(x=> this.config = x);
  }

  ngOnInit(): void {
  }

}
