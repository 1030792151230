import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { IConfig } from 'src/app/core/interfaces/config.interface';
import { ConfigService } from 'src/app/services/config.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  public configs: IConfig[] = [];

  constructor(
    private dataSvc: DataService,
    private storageSvc: StorageService,
    private router: Router,
    private themeSvc: ThemeService,
    private renderer2: Renderer2,
    private configSvc: ConfigService
  ) {
    this.configSvc.setConfiguration(location.hostname, location.pathname);
    this.dataSvc.getConfigs().subscribe((x) => (this.configs = x));
  }

  ngOnInit(): void {}

  changeTheme(config: IConfig) {

    this.storageSvc.setConfig(config);

    if(config.isCustomDomain){
      window.location.href  = `https://${config.baseUrl}${config.subdomain}`;
    }
    else{
      this.themeSvc.setTheme(config.id, this.renderer2);
      this.router.navigate([config.url + config.subdomain]);
    }
  }
}
