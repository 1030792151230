<div class="main-content employeesMain">
  <div class="container"*ngIf="config">
    <div class="caption">
      <h1 class="mb-2">{{config?.heading}} safety induction form for Employees</h1>
      <p>In order to complete your safety induction, please fill up the form below.</p>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <form [formGroup]="inductionForm" (ngSubmit)="submitInduction()">
          <div class="row">
            <div class="col-lg-5 col-md-5">
              <div class="row">
                <div class="col-md-12 col-sm-6">
                  <div class="row align-items-center form-group">
                    <div class="col-md-4">
                      <label class="required">Employee Number:</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" class="form-control" formControlName="employeeId">
                    </div>
                  </div>
                  <div class="row align-items-center form-group">
                    <div class="col-md-4">
                      <label class="required">Please Select the site you are visiting:</label>
                    </div>
                    <div class="col-md-8">
                      <ng-select [items]="sites" placeholder="Select Site..." formControlName="site" bindLabel="name"
                        bindValue="name" (change)="onSiteChange($event)">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-6" *ngIf="isOtherSite">
                  <div class="row align-items-center form-group">
                    <div class="col-md-4">
                      <label class="required">Other Site:</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" class="form-control" formControlName="otherSite">
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-6">
                  <div class="row align-items-center form-group">
                    <div class="col-md-4">
                      <label class="required">{{config?.heading}} Contact on Site:</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" class="form-control" formControlName="alecContactName">
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-6">
                  <div class="row align-items-center form-group">
                    <div class="col-md-4">
                      <label class="required">Contact Person's Number:</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" class="form-control" formControlName="alecContactPhone"
                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-6">
                  <div class="row align-items-center form-group">
                    <div class="col-md-4">
                      <label class="required">{{config.heading}} contact email:</label>
                    </div>
                    <div class="col-md-8">
                      <input type="email" class="form-control" formControlName="alecContactEmail">
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <h3>Data Privacy Policy</h3>
                    <input type="checkbox" id="checkbox" name="policy" class="checkbox-style"
                      formControlName="isShareAgree">
                    <label for="checkbox" class="required"><span>I agree to share my information with
                        {{config?.companyName}}.</span> </label>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <h3>Safety induction Agreement</h3>
                    <input type="checkbox" id="checkbox2" name="agreement" class="checkbox-style"
                      formControlName="isSafetyAgree">
                    <label for="checkbox2" class="required"><span>I agree to comply with all safety procedures
                        throughout my visit on
                        site.</span> </label>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-lg-7 col-md-7">
              <div class="row form-group">
                <div class="col-md-4 col-sm-6">
                  <label for="" class="required">Date and Time of upcoming visit</label>
                </div>
                <div class="col-md-4">
                  <input type="date" class="form-control " formControlName="visitDateTime" [min]="minDate"
                    onkeydown="return false;">
                </div>
                <div class="col-md-4">
                  <input type="time" class="form-control " formControlName="visitTime" onkeydown="return false;">
                </div>
              </div>
              <div class="row align-items-center form-group">
                <div class="col-md-4">
                </div>
                <div class="col-md-8">

                  <ngx-recaptcha2 #captchaElem formControlName="recaptcha" [siteKey]="siteKey">

                  </ngx-recaptcha2>
                </div>
              </div>

            </div>
          </div>
          <div class="text-center mt-3">
            <button type="submit" class="btn btn-primary" [disabled]="!inductionForm.valid || isSubmitted">Next</button>
            &nbsp;
            &nbsp;
            &nbsp;

            <a routerLink="{{config.url + '/survey'}}" class="btn btn-default">Back</a>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>