import { Component, OnInit } from '@angular/core';
import { IConfig } from 'src/app/core/interfaces/config.interface';
import { ConfigService } from 'src/app/services/config.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-welcome-safety-induction',
  templateUrl: './welcome-safety-induction.component.html',
  styleUrls: ['./welcome-safety-induction.component.scss'],
})
export class WelcomeSafetyInductionComponent implements OnInit {
  config: IConfig;

  constructor(
    private storageSvc: StorageService,
    private configSvc: ConfigService
  ) {
    this.configSvc.setConfiguration(location.hostname, location.pathname);
    this.storageSvc.configValue.subscribe((x) => (this.config = x));
  }

  ngOnInit(): void {}
}
