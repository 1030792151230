import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IConfig } from 'src/app/core/interfaces/config.interface';
import { ConfigService } from 'src/app/services/config.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-complete-safety-induction',
  templateUrl: './complete-induction.component.html',
  styleUrls: ['./complete-induction.component.scss'],
})
export class CompleteInductionComponent implements OnInit {
  config: IConfig;
  isOld: boolean = false;
  response: any;
  constructor(
    private route: ActivatedRoute,
    private storageSvc: StorageService,
    private configSvc: ConfigService
  ) {
    this.configSvc.setConfiguration(location.hostname, location.pathname);
    this.storageSvc.configValue.subscribe((x) => (this.config = x));

    this.route.queryParams.subscribe((params) => {
      this.response = JSON.parse(params['response']);

      if (this.response && this.response.isOld) {
        this.isOld = true;
      }
    });
  }

  ngOnInit(): void {}
}
