<section class="main-content video-content">
  <div class="container"*ngIf="config">
    <div class="caption">
      <h1>Your safety is our priority</h1>
      <p>This video is a part of your safety orientation. <br>
        After viewing the safety video, please proceed to fill up the safety induction form.</p>
    </div>
    <div class="videoWrapper">
      <!-- <div class="ratio ratio-16x9">
      
        <video src="https://assets.alec.ae/corporate-videos/SafetyVideo/Alec_Safety_Video.mp4"
               id="myVideo"
               class="w-100"
               (ended)="onVideoEnd()"></video>
      </div>
      <button class="btn-play icon-control"
              id="playBtn"
              onclick="(document.getElementById('myVideo').play(), document.getElementById('playBtn').style.display = 'none') "><img
             src="../../assets/images/play.svg"
             alt=""></button>
      <button class="btn-replay icon-control"
              id="demo"
              onclick="(document.getElementById('myVideo').play(), document.getElementById('demo').style.display = 'none'); "><img
             src="../../assets/images/replay.svg"
             alt=""></button> -->

      <vg-player>
        <vg-overlay-play></vg-overlay-play>
        <!-- <vg-buffering></vg-buffering> -->
        <!-- <vg-scrub-bar>
          <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
          <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar> -->
        <vg-controls>
          <!-- <vg-play-pause></vg-play-pause> -->
          <!-- <vg-playback-button></vg-playback-button> -->
          <vg-time-display vgProperty="current"
                           vgFormat="mm:ss"></vg-time-display>
          <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>
          <!-- <vg-time-display vgProperty="left"
                           vgFormat="mm:ss"></vg-time-display> -->
          <vg-time-display vgProperty="total"
                           vgFormat="mm:ss"></vg-time-display>
          <!-- <vg-track-selector></vg-track-selector> -->
          <!-- <vg-mute></vg-mute> -->
          <!-- <vg-volume></vg-volume> -->
          <vg-fullscreen></vg-fullscreen>
        </vg-controls>
        <video [vgMedia]="$any(media)"
               #media
               id="singleVideo"
               preload="auto"
               (ended)="onVideoEnd()">
          <source src="{{config?.videoUrl}}"
                  type="video/mp4">
        <!--<video [vgMedia]="$any(media)"
               #media
               id="singleVideo"
               preload="auto"
               crossorigin
               (ended)="onVideoEnd()">
          <source src="../../../assets/media/production.mp4"
                  type="video/mp4">
           <source src="https://assets.alec.ae/corporate-videos/SafetyVideo/Alec_Safety_Video.mp4"
                  type="video/mp4">
          <source src="http://static.videogular.com/assets/videos/videogular.ogg"
                  type="video/ogg">
          <source src="http://static.videogular.com/assets/videos/videogular.webm"
                  type="video/webm">
          <track kind="subtitles"
                 label="English"
                 src="http://static.videogular.com/assets/subs/pale-blue-dot.vtt"
                 srclang="en"
                 default>
          <track kind="subtitles"
                 label="Español"
                 src="http://static.videogular.com/assets/subs/pale-blue-dot-es.vtt"
                 srclang="es"> -->
        </video>
      </vg-player>
    </div>
    <div class="text-center">
      <a routerLink="{{config.url + '/survey'}}"
         class="btn btn-primary disabled"
         id="lock"><i><img src="../../assets/images/lock.svg"
               alt=""></i> Next</a>
    </div>
  </div>
</section>